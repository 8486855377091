import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../components/contact-us/hero';
import ContactInfo from '../components/contact-us/contact-info';

const ContactPage = () => (
  <Layout>
    <SEO
      title='Get In Touch'
      description='Questions about the NL Genome Project? We’d love to hear from you! Get in touch at hi@nlgenomeproject.ca or 709.800.2748.'
    />
    <Hero />
    <ContactInfo />
  </Layout>
);

export default ContactPage;
