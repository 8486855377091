import React from 'react';

const Hero = () => (
  <div className='contact-hero'>
    <div className='container mx-auto pt-24 lg:pt-32 pb-16 px-8'>
      <div className='lg:w-2/5'>
        <h1 className='font-sans font-bold text-white text-4xl lg:text-5xl leading-very-tight mb-4'>
          Contact us
        </h1>

        <h4 className='font-soft font-semibold text-blue-900 text-lg leading-snug mb-4'>
          We’d love to hear from you!
        </h4>

        <p className='font-sans font-normal text-white leading-snug'>
          Please do not send any personal health information to the below addresses.
        </p>
      </div>
    </div>
  </div>
);

export default Hero;
